import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

const urlParams = new URLSearchParams(window.location.search);

if (urlParams.get('journal[sortBy]') == null) {
  urlParams.set('journal[sortBy]', 'journal:published_timestamp:desc');
  window.location.search = urlParams;
}

const search = instantsearch({
  indexName: "journal",
  routing: true,
  searchClient: instantMeiliSearch(
    "https://meili.nations.lol",
    process.env.MEILI_KEY,
    {
      finitePagination: true
    }
  )
  .searchClient
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: "#searchbox"
  }),
  instantsearch.widgets.sortBy({
    container: "#sortby",
    items: [
      { label: 'Newest', value: 'journal:published_timestamp:desc'},
      { label: 'Oldest', value: 'journal:published_timestamp:asc'},
    ]
  }),
  instantsearch.widgets.configure({
    hitsPerPage: 6,
    snippetEllipsisText: "...",
    attributesToSnippet: ["content:50"],
  }),
  instantsearch.widgets.hits({
    container: "#hits",
    templates: {
      item: `
        <div>
          <a class="hit-name" href="/master/{{id}}.html">
            {{#helpers.highlight}}{ "attribute": "title" }{{/helpers.highlight}}
          </a>
          <div class="hit-description">
            {{#helpers.snippet}}{ "attribute": "content" }{{/helpers.snippet}}
          </div>
          <div class="hit-info">authors: {{authors}}</div>
          <div class="hit-info">published: {{published}}</div>
        </div>
      `
    }
  }),
  instantsearch.widgets.pagination({
    container: "#pagination"
  })
]);

search.start();



